import React from 'react';

import { Row, Col } from '/src/components/atoms/GridRow';
import YouTubeEmbed from '/src/components/atoms/YouTubeEmbed';
import Layout from '/src/components/layout';
import SEO from '/src/components/atoms/Seo';
import Button from '/src/components/atoms/Button';
import ContentSection from '/src/components/atoms/ContentSection';

const MusicPage = () => (
  <Layout hideHeader>
    <SEO title="Music" />
    <ContentSection banner parallax center bgSrc="/images/uploads/eoa_jen.jpg">
      <h1 className="heading1">Music</h1>
      <Button
        cta
        component="a"
        href="//itunes.apple.com/us/album/staircases-ep/id890661354"
        target="_blank"
      >
        Buy on iTunes
      </Button>
    </ContentSection>
    <ContentSection center light>
      <h2 className="heading2">Songs</h2>
      <Row center vertGutter>
        <Col width={6}>
          <YouTubeEmbed title="Kentucky Lyric Video" youtubeId="x51Lyo3Qk2k" />
        </Col>
      </Row>
      <Row center vertGutter>
        <Col width={6}>
          <YouTubeEmbed
            title="Pleasantville Lyric Video"
            youtubeId="fEcvIrf1qEg"
          />
        </Col>
      </Row>
      <Row center vertGutter>
        <Col width={6}>
          <YouTubeEmbed
            title="The Cruelest Love Live Video"
            youtubeId="UE8VxmufNnM"
          />
        </Col>
      </Row>
    </ContentSection>
  </Layout>
);

export default MusicPage;
